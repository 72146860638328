// search wocmen constants;
export const SEARCH_WOCMEN_PENDING = "SEARCH_WOCMEN_PENDING";
export const SEARCH_WOCMEN_SUCCESS = "SEARCH_WOCMEN_SUCCESS";
export const SEARCH_WOCMEN_FAIL = "SEARCH_WOCMEN_FAIL";
export const CLEAR_SEARCH_TOAST = "CLEAR_SEARCH_TOAST ";

// newsletter
export const NEWSLETTER_PENDING = "NEWSLETTER_PENDING";
export const NEWSLETTER_SUCCESS = "NEWSLETTER_SUCCESS";
export const NEWSLETTER_FAIL = "NEWSLETTER_FAIL";
export const CLEAR_NEWSLETTER_TOAST = "CLEAR_NEWSLETTER_TOAST ";

// contact us
export const CONTACT_PENDING = "CONTACT_PENDING";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAIL = "CONTACT_FAIL";
export const CLEAR_CONTACT_TOAST = "CLEAR_CONTACT_TOAST ";

// sign up
export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CLEAR_REGISTER_TOAST = "CLEAR_REGISTER_TOAST ";

// sign in
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_LOGIN_TOAST = "CLEAR_LOGIN_TOAST ";
export const LOGOUT = "LOGOUT";

// wocman dashboard workdone
export const WORKDONE_PENDING = "WORKDONE_PENDING";
export const WORKDONE_SUCCESS = "WORKDONE_SUCCESS";
export const WORKDONE_FAIL = "WORKDONE_FAIL";
export const CLEAR_WORKDONE_TOAST = "CLEAR_WORKDONE_TOAST";

// wocman dashboard ratings
export const RATINGS_PENDING = "RATINGS_PENDING";
export const RATINGS_SUCCESS = "RATINGS_SUCCESS";
export const RATINGS_FAIL = "RATINGS_FAIL";
export const CLEAR_RATINGS_TOAST = "CLEAR_RATINGS_TOAST";

// wocman dashboard-completed
export const COMPLETED_PENDING = "COMPLETED_PENDING";
export const COMPLETED_SUCCESS = "COMPLETED_SUCCESS";
export const COMPLETED_FAIL = "COMPLETED_FAIL";
export const CLEAR_COMPLETED_TOAST = "CLEAR_COMPLETED_TOAST";

// wocman dashboard-wallet
export const WALLET_PENDING = "WALLET_PENDING";
export const WALLET_SUCCESS = "WALLET_SUCCESS";
export const WALLET_FAIL = "WALLET_FAIL";
export const CLEAR_WALLET_TOAST = "CLEAR_WALLET_TOAST";

// wocman dashboard-notice
export const NOTICE_PENDING = "NOTICE_PENDING";
export const NOTICE_SUCCESS = "NOTICE_SUCCESS";
export const NOTICE_FAIL = "NOTICE_FAIL";
export const CLEAR_NOTICE_TOAST = "CLEAR_NOTICE_TOAST";

// wocman dashboard-schedule
export const SCHEDULE_PENDING = "SCHEDULE_PENDING";
export const SCHEDULE_SUCCESS = "SCHEDULE_SUCCESS";
export const SCHEDULE_FAIL = "SCHEDULE_FAIL";
export const CLEAR_SCHEDULE_TOAST = "CLEAR_SCHEDULE_TOAST";

// wocman dashboard-project-type
export const PROJECT_TYPE_PENDING = "PROJECT_TYPE_PENDING";
export const PROJECT_TYPE_SUCCESS = "PROJECT_TYPE_SUCCESS";
export const PROJECT_TYPE_FAIL = "PROJECT_TYPE_FAIL";
export const CLEAR_PROJECT_TYPE_TOAST = "CLEAR_PROJECT_TYPE_TOAST";

// wocman dashboard-project-customer
export const PROJECT_CUSTOMER_PENDING = "PROJECT_CUSTOMER_PENDING";
export const PROJECT_CUSTOMER_SUCCESS = "PROJECT_CUSTOMER_SUCCESS";
export const PROJECT_CUSTOMER_FAIL = "PROJECT_CUSTOMER_FAIL";
export const CLEAR_PROJECT_CUSTOMER_TOAST = "CLEAR_PROJECT_CUSTOMER_TOAST";

// wocman wocstation log-chat
export const LOG_CHAT_PENDING = "LOG_CHAT_PENDING";
export const LOG_CHAT_SUCCESS = "LOG_CHAT_SUCCESS";
export const LOG_CHAT_FAIL = "LOG_CHAT_FAIL";

// wocman wocstation send-chat
export const SEND_CHAT_PENDING = "SEND_CHAT_PENDING";
export const SEND_CHAT_SUCCESS = "SEND_CHAT_SUCCESS";
export const SEND_CHAT_FAIL = "SEND_CHAT_FAIL";
export const CLEAR_SEND_CHAT_TOAST = "CLEAR_SEND_CHAT_TOAST";

// wocman wocstation project-customer
export const FETCH_PROJECT_PENDING = "FETCH_PROJECT_PENDING";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAIL = "FETCH_PROJECT_FAIL";

// wocman wocstation accept-project
export const ACCEPT_PROJECT_PENDING = "ACCEPT_PROJECT_PENDING";
export const ACCEPT_PROJECT_SUCCESS = "ACCEPT_PROJECT_SUCCESS";
export const ACCEPT_PROJECT_FAIL = "ACCEPT_PROJECT_FAIL";
export const CLEAR_ACCEPT_PROJECT_TOAST = "CLEAR_ACCEPT_PROJECT_TOAST";

// wocman wocstation reject-project
export const REJECT_PROJECT_PENDING = "REJECT_PROJECT_PENDING";
export const REJECT_PROJECT_SUCCESS = "REJECT_PROJECT_SUCCESS";
export const REJECT_PROJECT_FAIL = "REJECT_PROJECT_FAIL";
export const CLEAR_REJECT_PROJECT_TOAST = "CLEAR_REJECT_PROJECT_TOAST";

// wocman wocstation start-project
export const START_PROJECT_PENDING = "START_PROJECT_PENDING";
export const START_PROJECT_SUCCESS = "START_PROJECT_SUCCESS";
export const START_PROJECT_FAIL = "START_PROJECT_FAIL";
export const CLEAR_START_PROJECT_TOAST = "CLEAR_START_PROJECT_TOAST";

// wocman wocstation stop-project
export const STOP_PROJECT_PENDING = "STOP_PROJECT_PENDING";
export const STOP_PROJECT_SUCCESS = "STOP_PROJECT_SUCCESS";
export const STOP_PROJECT_FAIL = "STOP_PROJECT_FAIL";
export const CLEAR_STOP_PROJECT_TOAST = "CLEAR_STOP_PROJECT_TOAST";

// wocman wocstation complete-project
export const COMPLETE_PROJECT_PENDING = "COMPLETE_PROJECT_PENDING";
export const COMPLETE_PROJECT_SUCCESS = "COMPLETE_PROJECT_SUCCESS";
export const COMPLETE_PROJECT_FAIL = "COMPLETE_PROJECT_FAIL";
export const CLEAR_COMPLETE_PROJECT_TOAST = "CLEAR_COMPLETE_PROJECT_TOAST";

// wocman wocstation history
export const HISTORY_PENDING = "HISTORY_PENDING";
export const HISTORY_SUCCESS = "HISTORY_SUCCESS";
export const HISTORY_FAIL = "HISTORY_FAIL";
export const CLEAR_HISTORY_TOAST = "CLEAR_HISTORY_TOAST";

// wocman wocstation contacts
export const CONTACTS_PENDING = "CONTACTS_PENDING";
export const CONTACTS_SUCCESS = "CONTACTS_SUCCESS";
export const CONTACTS_FAIL = "CONTACTS_FAIL";
export const CLEAR_CONTACTS_TOAST = "CLEAR_CONTACTS_TOAST";

// wocman wocstation customer-chat
export const CUSTOMER_CHAT_PENDING = "CUSTOMER_CHAT_PENDING";
export const CUSTOMER_CHAT_SUCCESS = "CUSTOMER_CHAT_SUCCESS";
export const CUSTOMER_CHAT_FAIL = "CUSTOMER_CHAT_FAIL";
export const CLEAR_CUSTOMER_CHAT_TOAST = "CLEAR_CUSTOMER_CHAT_TOAST";

// wocman wocstation wocman-project
export const WOCMAN_PROJECT_PENDING = "WOCMAN_PROJECT_PENDING";
export const WOCMAN_PROJECT_SUCCESS = "WOCMAN_PROJECT_SUCCESS";
export const WOCMAN_PROJECT_FAIL = "WOCMAN_PROJECT_FAIL";
export const CLEAR_WOCMAN_PROJECT_TOAST = "CLEAR_WOCMAN_PROJECT_TOAST";

// wocman wocstation wocman-chat
export const WOCMAN_CHAT_PENDING = "WOCMAN_CHAT_PENDING";
export const WOCMAN_CHAT_SUCCESS = "WOCMAN_CHAT_SUCCESS";
export const WOCMAN_CHAT_FAIL = "WOCMAN_CHAT_FAIL";
export const CLEAR_WOCMAN_CHAT_TOAST = "CLEAR_WOCMAN_CHAT_TOAST";

// customer wocstation send-chat
export const CUSTOMER_SEND_CHAT_PENDING = "CUSTOMER_SEND_CHAT_PENDING";
export const CUSTOMER_SEND_CHAT_SUCCESS = "CUSTOMER_SEND_CHAT_SUCCESS";
export const CUSTOMER_SEND_CHAT_FAIL = "CUSTOMER_SEND_CHAT_FAIL";
export const CLEAR_CUSTOMER_SEND_CHAT_TOAST = "CLEAR_CUSTOMER_SEND_CHAT_TOAST";

// wocman wocstation contacts
export const JOB_CONTACTS_PENDING = "JOB_CONTACTS_PENDING";
export const JOB_CONTACTS_SUCCESS = "JOB_CONTACTS_SUCCESS";
export const JOB_CONTACTS_FAIL = "JOB_CONTACTS_FAIL";
export const CLEAR_JOB_CONTACTS_TOAST = "CLEAR_JOB_CONTACTS_TOAST";
